import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
]

export default function Header() {
    return (
        <div>
            <div className="relative overflow-hidden bg-white shadow-md">
                <div className="mx-auto max-w-7xl">
                    <div className="relative z-10 bg-white lg:max-w-2xl lg:w-full">
                        <svg
                            className="absolute inset-y-0 right-0 hidden w-48 h-full text-white transform translate-x-1/2 lg:block"
                            fill="currentColor"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="50,0 100,0 50,100 0,100" />
                        </svg>

                        <Popover>
                            {({ open }) => (
                                <>
                                    <div className="relative px-4 py-6 sm:px-6 lg:px-8">
                                        <nav
                                            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                                            aria-label="Global"
                                        >
                                            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                                <div className="flex items-center justify-between w-full md:w-auto">
                                                    <a href="#">
                                                        <span className="sr-only">Workflow</span>
                                                        <img
                                                            className="w-auto h-8 sm:h-10"
                                                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                                        />
                                                    </a>
                                                    <div className="flex items-center -mr-2 md:hidden">
                                                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                            <span className="sr-only">Open main menu</span>
                                                            <MenuIcon className="w-6 h-6" aria-hidden="true" />
                                                        </Popover.Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                                                {navigation.map((item) => (
                                                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                                                        {item.name}
                                                    </a>
                                                ))}
                                                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    Log in
                      </a>
                                            </div>
                                        </nav>
                                    </div>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="duration-150 ease-out"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="duration-100 ease-in"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Popover.Panel
                                            focus
                                            static
                                            className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
                                        >
                                            <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                                                <div className="flex items-center justify-between px-5 pt-4">
                                                    <div>
                                                        <img
                                                            className="w-auto h-8"
                                                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="-mr-2">
                                                        <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                            <span className="sr-only">Close main menu</span>
                                                            <XIcon className="w-6 h-6" aria-hidden="true" />
                                                        </Popover.Button>
                                                    </div>
                                                </div>
                                                <div className="px-2 pt-2 pb-3 space-y-1">
                                                    {navigation.map((item) => (
                                                        <a
                                                            key={item.name}
                                                            href={item.href}
                                                            className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                                                        >
                                                            {item.name}
                                                        </a>
                                                    ))}
                                                </div>
                                                <a
                                                    href="#"
                                                    className="block w-full px-5 py-3 font-medium text-center text-indigo-600 bg-gray-50 hover:bg-gray-100"
                                                >
                                                    Log in
                      </a>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    )
}
